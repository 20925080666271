import Style from "./Summary.module.scss";
import TermsandConditions from "../TermsandConditions";
import useSummary from "./useSummary";
import PaymentSuccess from "../PaymentSuccess";
import useCheckToken from "../../logic/useCheckToken";
import { useContext } from "react";
import { GlobalContext } from "../../context";
const OrderSuccess = () => {
  useCheckToken();
  const { t, userData } = useSummary();
  const { getTextById, isLoading } = useContext(GlobalContext);

  return (
    <>
      <section className={Style.delivery_subscription}>
        <div className="container">
          <div className="row">
            <PaymentSuccess
              getTextById={getTextById}
              t={t}
              userData={userData}
            />
          </div>
          <TermsandConditions getTextById={getTextById} />
        </div>
      </section>
    </>
  );
};

export default OrderSuccess;
