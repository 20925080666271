import { useEffect } from "react";
import i18n from "../../i18n";
import { useParams, useNavigate } from "react-router-dom";
import Style from "./Home.module.scss";
import { useRtl } from "../../logic/useRtl";

const useHome = () => {
  const { lang: langSlug } = useParams();
  const navigate = useNavigate();
  const { rtl } = useRtl({ Style });

  // Set language based on URL langSlug or use stored language if not present in the URL
  useEffect(() => {
    const storedLang = localStorage.getItem("language_type");

    if (langSlug) {
      // If langSlug is provided in the URL, set it in localStorage
      localStorage.setItem("language_type", langSlug);
    } else if (storedLang) {
      // If langSlug is not provided but language is stored, navigate based on stored language
      navigate(`/${storedLang}`);
    } else {
      // If neither langSlug nor stored language is available, default to "en"
      localStorage.setItem("language_type", "en");
      navigate(`/en`);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  return { rtl };
};

export default useHome;
