import { useFormik } from "formik";
import { parseCookies, setCookie } from "nookies";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { OTPverify, ResendOTP } from "./api";
import useLogout from "../Logout/useLogout";

const useVerifyOTP = ({ onHides, isTimerRunning, setIsTimerRunning }) => {
  const [timer, setTimer] = useState(59);
  const { mutate } = useLogout();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  let user_id = parseCookies()?.userID;

  var numbers = /^\d+\.?\d*$/;

  const formik = useFormik({
    initialValues: {
      otp: "",
    },

    validationSchema: Yup.object({
      otp: Yup.string().required(t("OTP_required")),
    }),
    validate: (values) => {
      let errors = {};
      if (!values.otp) {
        errors.otp = t("OTP_required");
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      let data = {
        otp: values?.otp,
        user_code: user_id,
      };
      if (values?.otp?.toString()?.length < 6) {
        formik.setFieldError("otp", t("OTP_invalid"));
      }
      setIsSubmitLoading(true);

      OTPverify(data)
        .then((response) => {
          if (response?.data?.code == "200") {
            const lang = localStorage.getItem("language_type");
            setIsSubmitLoading(false);
            setCookie(
              null,
              "USER_ACCESS_TOKEN",
              response?.data?.data?.access_token,
              {
                maxAge: 24 * 60 * 60,
                path: "/",
              }
            );
            resetForm();
            mutate("/user-details");
            navigate(`/subscription/${lang}`);
            onHides();
          } else {
            setIsSubmitLoading(false);
            formik.setFieldError("otp", response?.message);
          }
        })
        .catch(() => {
          setIsSubmitLoading(false);
        });
    },
  });

  useEffect(() => {
    let interval;
    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning, timer]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerRunning(false);
    }
  }, [timer]);

  const handleResendOTP = () => {
    setIsTimerRunning(true);
    setTimer(59);
  };

  const handleReset = () => {
    formik.resetForm();
  };

  const handleResend = () => {
    handleResendOTP();
    formik.resetForm();
    let data = {
      user_code: user_id,
    };
    ResendOTP(data).then((response) => {
      if (response?.data?.status_code == 200) {
        setTimeout(() => {
          setIsTimerRunning(false);
        }, 500);
      } else {
        formik.setFieldError("otp", response?.message);
      }
    });
  };

  return {
    t,
    setTimer,
    timer,
    formik,
    isTimerRunning,
    isSubmitLoading,
    handleReset,
    handleResend,
  };
};

export default useVerifyOTP;
