import { getAxiosInstance } from "../../apis";

export const logout = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/logout");
      return response;
    } catch (error) {
      return error.response.data;
    }
  };