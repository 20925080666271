import React from "react";
import Style from "../Home/Home.module.scss";
import { useParams } from "react-router-dom";

const TermsandConditions = ({ getTextById }) => {
  const ids = Array.from({ length: 12 }, (_, index) => index + 22);
  const { lang: langSlug } = useParams();
  const lang = localStorage.getItem("language_type") ?? langSlug;
  return (
    <div
      dir={lang === "en" ? "ltr" : "rtl"}
      className={Style.termsandcondition}
    >
      <h2 className="h2">{getTextById(21)}</h2>
      <ol>
        {ids.map((id) => (
          <li key={id}>
            <p>{getTextById(id)}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TermsandConditions;
