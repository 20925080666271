import { destroyCookie, parseCookies } from "nookies";
import { logout } from "./api";
import { useNavigate, useParams } from "react-router-dom";
import { useSwrData } from "../../helpers/swr";
const useLogout = () => {
  const token = parseCookies()?.USER_ACCESS_TOKEN;
  const navigate = useNavigate();
  const { lang: langSlug } = useParams();
  const lang = localStorage.getItem("language_type") ?? langSlug;

  const { data, mutate } = useSwrData(
    `/user-details`,
    {},
    {
      language: lang,
    }
  );
  const handleLogout = () => {
    logout()
      .then((d) => {
        destroyCookie(null, "USER_ACCESS_TOKEN", { path: "/" });
        navigate(`/${lang}`);
        localStorage.clear();
        localStorage.setItem("language_type", lang);
      })
      .catch((err) => {
        console.log("failed to logout");
      });
  };
  return { token, handleLogout, data, mutate };
};

export default useLogout;
