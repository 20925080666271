import Style from "./Subscription.module.scss";
import SubscriptionText from "../SubscriptionText";
import SubscriptionDate from "../SubscriptionDate";
import FormPayment from "../FormPayment";
import useSubscription from "./useSubscription";
import SubscriptionExpired from "../SubscriptionExpired";
import SubscriptionSuccess from "../SubscriptionSuccess";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../context/index";
const Subscription = () => {
  const { userData, basicData, orderId } = useSubscription();
  const { getTextById, isLoading } = useContext(GlobalContext);

  let paymentOptionStatus = userData?.data?.payment_option;
  const location = useLocation();
  const currentPath = location.pathname;

  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";
  console.log(rtl);
  

  return (
    <>
      <section className={`${Style.delivery_subscription} ${rtl}`}>
        <div className="section_wrap">
          <div className="inner">
            <div className={`row`}>
              <div className={`col-lg-6`}>
                {paymentOptionStatus == "1" && (
                  <SubscriptionText getTextById={getTextById} />
                )}
                {paymentOptionStatus == "2" && (
                  <SubscriptionExpired getTextById={getTextById} />
                )}
                {paymentOptionStatus == "0" && (
                  <SubscriptionSuccess getTextById={getTextById} />
                )}
                <SubscriptionDate getTextById={getTextById} props={userData} />
                <div className="d-block d-lg-none">
                  <figure className={Style.figure}>
                    <img
                      src="/assets/images/subscription-poster.png"
                      alt="poster"
                    />
                  </figure>
                </div>
                <FormPayment
                  userData={userData}
                  basicData={basicData}
                  orderId={orderId}
                />
              </div>
              <div className="col-lg-6">
                <div className="d-none d-lg-block">
                  <figure className={Style.figure}>
                    <img
                      src="/assets/images/subscription-poster.png"
                      alt="poster"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscription;
