import { useEffect } from "react";
import i18n from "../../i18n";
import { useParams, useNavigate } from "react-router-dom";
import { useSwrData, useSwrStatic } from "../../helpers/swr";
import { useTranslation } from "react-i18next";

const useSummary = () => {
  const { lang: langSlug } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  const checkLocalStorage = (key) => {
    return typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem(key))
      : null;
  };

  let order_id = checkLocalStorage("id");

  const { data: userData } = useSwrData(
    `/user-details`,
    {},
    {
      language: langSlug,
    }
  );
  const { data: orderstatus } = useSwrData(
    `/update-order-status`,
    {},
    {
      status: "failed",
      order_id,
    }
  );

  return { t, userData, orderstatus };
};

export default useSummary;
