import React from "react";
import Style from "./SubscriptionText.module.scss";
import useSubscriptionText from "./useSubscriptionText";

const SubscriptionText = ({ getTextById }) => {
  const { basicData, rtl } = useSubscriptionText();

  return (
    <div className={`${Style.subscription} ${rtl}`}>
      <h2 className={Style.title}>{getTextById(1)}</h2>
      <h2 className={`h2 ${Style.subtitle}`}>{getTextById(2)}</h2>
      <p>{getTextById(3)}</p>
      <ul>
        <li>{getTextById(4)}</li>
        <li>{getTextById(5)}</li>
      </ul>

      <h2 className={`h2 ${Style.sm_title}`}>
        <span className={`${rtl ? Style.amount_ar : Style.amount}`}>
          <span>{getTextById(45)}</span>
          <span>{parseInt(basicData?.data?.amount)}</span>
        </span>
        /{getTextById(42).toLowerCase()}
      </h2>
    </div>
  );
};

export default SubscriptionText;
