import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postMethodInstance } from "../Subscription/api";
import { useNavigate, useParams } from "react-router-dom";

const useFormPayment = ({ id }) => {
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [cardholderName, setCardholderName] = useState("");
  const [loadingShimmer, setLoadingShimmer] = useState(false);
  const [errorMsgCard, setErrorMsgCard] = useState("");
  const [cardClick, setCardClick] = useState(true);
  const [gpayClick, setGpayClick] = useState(false);

  const { t } = useTranslation(["common"]);

  const { lang: langSlug } = useParams();
  const navigate = useNavigate();
  let langString = langSlug;

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const handleOnCardTokenized = useCallback(
    async (data) => {
      try {
        const payload = {
          token: data?.token,
          card_type: data?.card_type,
          issuer_country: data?.issuer_country,
          scheme: data?.scheme,
          card_no: data?.last4,
          order_id: localStorage.getItem("id"),
        };
        postMethodInstance("create-payment-link", payload)
          .then(({ data }) => {
            let responseWith3D = data?.status;
            let responseWithOut3D = data?.approved;
            console.log(responseWith3D);
            console.log(data?._links?.redirect?.href);

            if (responseWith3D == "Pending") {
              setLoadingShimmer(false);
              window.location.href = data?._links?.redirect?.href;
            } else if (responseWithOut3D == true) {
              setLoadingShimmer(false);
              navigate(`/order-summary/${id}/success/${langString}`);
            } else if (responseWithOut3D == false) {
              setLoadingShimmer(false);
              navigate(`/order-summary/${id}/failed/${langString}`);
            }
          })
          .catch((err) => {
            setErrorMsgCard(t("Something_wrong"));
            setLoadingShimmer(false);
            setTimeout(() => setErrorMsgCard(""), 4000);
          });
        setLoadingShimmer(false);
      } catch (error) {
        console.log("On Card Tokenized Callback handler Error: ", error);
      }
    },
    [id]
  );

  const onCardValidationChanged = useCallback(
    (valid) => setPayButtonDisabled(!valid),
    [setPayButtonDisabled]
  );

  const handleCardholderName = useCallback(
    (e) => setCardholderName(e.target.value),
    [setCardholderName]
  );
  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(9), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };

  return {
    handleCardholderName,
    onCardValidationChanged,
    handleOnCardTokenized,
    payButtonDisabled: payButtonDisabled || !cardholderName,
    cardholderName,
    loadingShimmer,
    setLoadingShimmer,
    errorMsgCard,
    cardClick,
    setCardClick,
    gpayClick,
    setGpayClick,
    t,
    getDropList,
  };
};
export default useFormPayment;
