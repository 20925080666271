import { Modal } from "react-bootstrap";
import Style from "./RegisterModal.module.scss";
import useRegisterModal from "./useRegisterModal";

const RegisterModal = ({ show, handleClose }) => {
  const { iframeUrl } = useRegisterModal();
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter align-items-end"
        centered
        className={Style.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className={Style.modal_body}>
          <iframe
            frameborder="0"
            src={iframeUrl}
            title="IKEA Family Member Page"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              minHeight: "1550px",
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RegisterModal;
