import { useSwrStatic } from "../../helpers/swr";
import { setCookie } from "nookies";
import Style from "./SubscriptionText.module.scss";
import { useLocation } from "react-router-dom";

const useSubscriptionText = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  const { data: basicData } = useSwrStatic(`/basic-details`);

  if (basicData) {
    setCookie(null, "AMOUNT", basicData?.data?.amount);
  }

  return { rtl, basicData };
};

export default useSubscriptionText;
