import { useEffect, useState } from "react";
import i18n from "../../i18n";
import { useParams, useNavigate } from "react-router-dom";
import { useSwrData, useSwrStatic } from "../../helpers/swr";
import { setCookie } from "nookies";
import { useTranslation } from "react-i18next";
import { postMethodInstance } from "./api";

const useSubscription = () => {
  const { lang: langSlug } = useParams();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null);

  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  const { data: userData } = useSwrData(
    `/user-details`,
    {},
    {
      language: langSlug,
    }
  );
  const { data: basicData } = useSwrStatic(`/basic-details`);

  useEffect(() => {
    if (userData?.data?.payment_option == "1") {
      postMethodInstance("create-order", { language: langSlug })
        .then((data) => {
          setOrderId(data?.data?.id);
          localStorage.setItem("id", data?.data?.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userData?.data?.payment_option]);

  return {
    t,
    userData,
    basicData,
    orderId,
  };
};

export default useSubscription;
