import "../styles/common.scss";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import CommonLayout from "./Layouts/CommonLayout";
import SubscriptionPage from "../pages/Subscription";
// import RegisterPage from "../pages/Register";
import OrderSuccess from "./OrderSuccess";
import OrderFailed from "./OrderFailed";
import { PrivateRoute } from "./PrivateRoute";

const App = () => {
  return (
    <Suspense fallback={null}>
      <CommonLayout>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/:lang"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route path="/subscription/:lang" element={<SubscriptionPage />} />
          <Route
            path="/order-summary/:orderId/success/:lang"
            element={<OrderSuccess />}
          />
          <Route
            path="/order-summary/:orderId/failed/:lang"
            element={<OrderFailed />}
          />
        </Routes>
      </CommonLayout>
    </Suspense>
  );
};

export default App;
