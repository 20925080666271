import { Logout } from "../../Logout";
import Footer from "../Footer";
import Menu from "../Menu";
import useCommonLayout from "./useCommonLayout";

const CommonLayout = ({ children }) => {
  useCommonLayout();
  return (
    <>
      <Menu />
      <div className="switcher">
        <Logout />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default CommonLayout;
