import { Navigate, useParams } from "react-router-dom";
import { parseCookies } from "nookies";

export const PrivateRoute = ({ children }) => {
  const cookies = parseCookies();
  const { lang } = useParams();
  const language = localStorage.getItem("language_type");

  // Check for the specific cookie, e.g., `USER_ACCESS_TOKEN`
  if (!cookies.USER_ACCESS_TOKEN) {
    return children; // If the token is available, render the protected component
  } else {
    // Otherwise, redirect to the login page or another route
    return <Navigate to={`/subscription/${lang ?? language ?? "en"}`} />;
  }
};
