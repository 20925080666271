import React from "react";
import { Form } from "react-bootstrap";
import VerifyOTP from "../VerifyOTP";
import Style from "./LoginForm.module.scss";
import useLoginForm from "./useLoginForm";
import RegisterModal from "../RegisterModal";
import { useRtl } from "../../logic/useRtl";

const LoginForm = ({ getTextById }) => {
  const {
    formik,
    showModal,
    mobileRef,
    isTimerRunning,
    isSubmitLoading,
    showModalRegister,
    handleClose,
    setIsTimerRunning,
    handleShowRegister,
    handleCloseRegister,
  } = useLoginForm();
  const { rtl } = useRtl({ Style });

  return (
    <div className={`${Style.login} ${rtl}`}>
      <p>{getTextById(6)}</p>

      <form onSubmit={formik.handleSubmit}>
        <Form.Group className="form-group text-start">
          <Form.Label></Form.Label>
          <div className="relative">
            <input
              readOnly
              className={`${Style?.static_input} ${rtl} form-control`}
              value={"+974"}
            />
            <Form.Control
              type="text"
              id="mobile"
              onChange={formik.handleChange}
              maxLength={10}
              value={formik.values.mobile}
              className={`${Style.dynamic_input} ${rtl}`}
              placeholder="XXXXXXXX"
              name="mobile"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="formikError">{formik.errors.mobile}</div>
          ) : null}
        </Form.Group>

        <button
          className={`${
            isSubmitLoading ? "loading custom_verify_btn" : " custom_verify_btn"
          }`}
          type="sumbit"
        >
          {getTextById(7)}
        </button>
      </form>
      <VerifyOTP
        shows={showModal}
        mobileRef={mobileRef}
        isTimerRunning={isTimerRunning}
        onHides={handleClose}
        setIsTimerRunning={setIsTimerRunning}
        getTextById={getTextById}
      />

      <button className={Style.join} onClick={handleShowRegister}>
        {getTextById(20)}
      </button>
      <RegisterModal
        show={showModalRegister}
        handleClose={handleCloseRegister}
      />
    </div>
  );
};

export default LoginForm;
