import { useEffect } from "react";

const useCommonLayout = () => {
  const PRODUCTION = process.env.REACT_APP_PRODUCTION;
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      PRODUCTION === "true"
        ? process.env.REACT_APP_ADOBE_CDP_PRODUCTION
        : process.env.REACT_APP_ADOBE_CDP_STAGING;
    script.id = "adobe-cdp";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return {};
};

export default useCommonLayout;
