import React from "react";
import Style from "./logout.module.scss";
import useLogout from "./useLogout";
import { useTranslation } from "react-i18next";
import { useRtl } from "../../logic/useRtl";
export const Logout = () => {
  const { token, handleLogout, data } = useLogout();
  const { t } = useTranslation(["common"]);
  const { rtl } = useRtl({ Style });
  return (
    <>
      {token && (
        <div className={`${Style.wrapper}`}>
          <button
            className={`${Style?.logout_btn} ${rtl} mt-3`}
            onClick={handleLogout}
          >
            <span>
              {/* {data?.data?.user_name}{" "} */}
              <svg
                class="sidebar-sign_out_btn-3Z7"
                width="16"
                height="16"
                viewBox="0 0 982 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M493.327 623.435c-174.682 0-313.224-138.541-313.224-313.224s138.541-313.224 313.224-313.224 313.224 138.541 313.224 313.224c0 174.682-138.541 313.224-313.224 313.224zM493.327 93.365c-120.471 0-222.871 102.4-222.871 222.871s102.4 222.871 222.871 222.871 222.871-102.4 222.871-222.871c0-126.494-96.376-222.871-222.871-222.871z"
                  fill="#FFF"
                ></path>
                <path
                  d="M59.633 1008.941c-6.024 0-12.047 0-18.071-6.024-24.094-12.047-30.118-36.141-24.094-60.235 84.329-180.706 271.059-295.153 469.835-289.129 198.776-6.024 385.506 108.424 469.835 289.129 12.047 24.094 0 48.188-24.094 60.235s-48.188 0-60.235-24.094c-72.282-150.588-228.894-240.941-391.529-240.941-168.659-6.024-319.247 90.353-391.529 240.941 0 24.094-12.047 30.118-30.118 30.118z"
                  fill="#FFF"
                ></path>
              </svg>
              {t("SIGN_OUT")}
            </span>
          </button>
        </div>
      )}
    </>
  );
};
