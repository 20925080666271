import Style from "./Summary.module.scss";
import useSummary from "./useSummary";
import TransactionFailed from "./transaction-failed.svg";
import { useNavigate } from "react-router-dom";
import useCheckToken from "../../logic/useCheckToken";
import { GlobalContext } from "../../context";
import { useContext } from "react";

const OrderFailed = () => {
  useCheckToken();
  const { t } = useSummary();
  const { getTextById, isLoading } = useContext(GlobalContext);
  const navigate = useNavigate();
  const lang = localStorage.getItem("language_type");
  return (
    <>
      <section className={Style.delivery_subscription}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-6 ${Style.failedClass}`}>
              <div className={`${Style.desktop}`}>
                <div className={Style.contentWrapper}>
                  <div class={Style.fig_wrapper}>
                    <figure class={Style.fig_img_wrapper}>
                      <img src={TransactionFailed} alt="Transaction Failed" />
                    </figure>
                  </div>
                  <div className={Style.message_title}>{getTextById(46)}</div>
                  <div className={`${Style.message_status} ${Style.error}`}>
                    {getTextById(47)}
                  </div>
                  <button
                    className={"custom_btn custom_btn-primary"}
                    onClick={() => {
                      navigate(`/subscription/${lang}`);
                      window.location.reload();
                    }}
                  >
                    {getTextById(48)}
                  </button>
                  <button
                    className={"custom_btn custom_btn-primary"}
                    onClick={() => {
                      navigate(`/`);
                      window.location.reload();
                    }}
                  >
                    {getTextById(36)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderFailed;
