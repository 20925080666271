import { useParams } from "react-router-dom";

const useRegisterModal = () => {
  const { lang } = useParams();
  const iframeUrl =
    lang == "en"
      ? process.env.REACT_APP_REGISTER_IFRAME_EN
      : process.env.REACT_APP_REGISTER_IFRAME_AR;

  return { iframeUrl };
};

export default useRegisterModal;
