import React from "react";
import Style from "./SubscriptionDate.module.scss";
import { useLocation } from "react-router-dom";

const SubscriptionDetail = ({ getTextById, props }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  console.log(rtl);
  
  return (
    <div className={`${Style.subdate} ${rtl}`}>
      <div className={Style.box}>
        <h2 className={Style.title}>{getTextById(35)}</h2>
        <p>{props?.data?.end_date || "dd/mm/yyyy"}</p>
      </div>
    </div>
  );
};

export default SubscriptionDetail;
